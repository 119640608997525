
import {
  defineComponent,
  Ref,
  ref,
  onMounted,
watch,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import ModalUsers from "./components/ModalUsers.vue";
import Title from "@/components/Title.vue"
import Button from "@/components/Button.vue"
import { useRouter,useRoute, LocationQueryValue } from "vue-router";
import { Modal } from "bootstrap";
import Api from "@/services/Api";
import useEmitter from "@/composables/Emmiter";
import { useLoaderStore } from "@/store/LoaderStore";

interface Input {
  cargo: string,
  data: string
}

interface User {
  id: string | number | null;
  name: string | number | null;
  email: string | number | null;
  cpf: string | number | null;
  tel: string | number | null;
  email_verified_at: string | number | null;
  created_at: string | number | null;
  updated_at: string | number | null;
  id_cargo: string | number | null;
  projects: (string | number | null)[];
}



export default defineComponent({
  name: "Users",

  components: {
    ModalUsers,
    Title,
    Button
  },

  setup() {
    const router = useRouter();

    const route = useRoute();
    const emitter = useEmitter();
    const isLoading = useLoaderStore();
    const pagina: Ref<number> = ref(1);
    const limite: Ref<number> = ref(20);

    const usersArray: Ref<User[]> = ref([]);

    const usuarioModal: Ref<any> = ref({})
    const modalUsuario: Ref<any> = ref()

    //refs recebidos do FilterUser
    const input: Ref<Input> = ref({
      cargo: "",
      data: ""
    });

    const idLoja: Ref<LocationQueryValue[] | string | null> = ref(null)
    const lojaFixada: Ref<any> = ref(null)
    const fastFind: Ref<string> = ref('');


    function openModal(usuario?) {
      // pegarLojaId()
      usuarioModal.value = usuario || {}
      modalUsuario.value.show()

    }

    async function fechaModal() {
      
      modalUsuario.value.hide()

    }


    async function buscaUsuario(paginaParam: number,  search = false){

      const { data } = await Api.get("buscaUsuarioPaginado", { pagina: paginaParam, limite: limite.value, idLoja: idLoja.value, cargo: input.value.cargo, data: input.value.data , input: fastFind.value} )
      
      //quando eu chamo a busca de usuario pelo modal de filtro ao invez de adicionar quero que substitua o valor da array
      if(!search) usersArray.value = [...usersArray.value, ...data.usuarios]
      else usersArray.value = data.usuarios

    }

    async function getAllUsers(){
      isLoading.open()
      try {
        const {data} = await Api.get('users');
        usersArray.value = data;
      } catch (error) {
        console.log("error")
      }finally{
        isLoading.close()
      }
    }


    emitter.on("FilterUser", (valor: any) => {
      if(valor.input) input.value = valor.input;

      pagina.value = 1

      buscaUsuario(pagina.value,true)

    })

    emitter.on("enviaUsuarioAtualizado", (valor: any) => {

      getAllUsers()
      // const index = usersArray.value.findIndex(({id}) => id === valor.usuario.id)

      // if(index !== -1) usersArray.value[index] = { ...valor.usuario }
      // else usersArray.value.unshift({ ...valor.usuario })

    })

    emitter.on("removeUsuarioLista", (valor: any) => {

      getAllUsers()
      // const index = usersArray.value.findIndex(({id}) => id === valor.usuario.id)

      // if(index !== -1) usersArray.value.splice(index,1)

    })

    function getLocalStorage(){
      lojaFixada.value = JSON.parse(localStorage.getItem('lojaFixada') as any)      
      if(lojaFixada.value){
        idLoja.value = lojaFixada.value && lojaFixada.value.id
      }
    }

    
    
    //----------- colocar o filtro fazendo requisição
    function buscar(){

      if(fastFind.value.length > 2 || !fastFind.value){
          pagina.value = 1
          buscaUsuario(pagina.value, true)
        }
        
     }


    onMounted(async () => {
      getAllUsers()
      modalUsuario.value = new Modal(document.querySelector(`#ModalUsers`));
    })

    

    return {
      usersArray,
      limite,
      history,
      openModal,
      fechaModal,
      router,
      pagina,
      buscaUsuario,
      usuarioModal,
      idLoja,
      lojaFixada,
      fastFind,
      buscar,
      isLoading
    };
  },
});
