
import Api from "@/services/Api";
import { defineComponent, Ref, ref, onMounted, watch, reactive } from "vue";
import type { PropType } from "vue";
import useAlert from '@/composables/Alert'
import useEmitter from "@/composables/Emmiter";
import Button from "@/components/Button.vue"
import type { FormInstance, FormRules } from 'element-plus'


interface RuleForm {
  name: string
  email: string
  password: string
  projects: string[]
  id_cargo: string
  cpf: string
  tel: string
  id: number | null
}



export default defineComponent({
  name: "ModalUsers",
  components: {
    Button,
  },
  props: {
    usuario: Object as PropType<any>,
    fechaModal: Function,
    abreModal: Function,
  },

  setup(props) {

    const { showTimeAlert } = useAlert()
    const emitter = useEmitter()

    const ruleFormRef = ref<FormInstance>()
    const user  = reactive<RuleForm>({
      name: '',
      email: '',
      password: '',
      projects: [],
      id_cargo: '',
      cpf: '',
      tel: '',
      id: null
    });

    // const validatePassword = (rule, value, callback) => {
    //   if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])/.test(value)) {
    //     callback(new Error('A senha deve conter caracteres maiúsculos, minúsculos e numéricos'));
    //   } else {
    //     callback();
    //   }
    // };
    // const validateCPF = (rule, value, callback) => {
    //   const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    //   if (!value) {
    //     callback(new Error('CPF is required'));
    //   } else if (!cpfRegex.test(value)) {
    //     callback(new Error('Invalid CPF format'));
    //   } else {
    //     callback();
    //   }
    // };

    const rules =  reactive<FormRules<RuleForm>>({
      name: [
        { required: true, message: 'Nome obrigatório', trigger: 'blur' },
        // { min: 3, max: 5, message: 'Minimo de 3 letras', trigger: 'blur' },
      ],
      email: [
        { required: true, message: 'E-mail obrigatório', trigger: 'blur' },
        { type: 'email', message: 'Por favor, insira um endereço de e-mail válido', trigger: ['blur', 'change'] },
      ],
      password: [
        { required: true, message: 'Senha obrigatório' },
        { min: 4, message: 'Mínimo de 6 caracteres'},
      ],
      cpf: [
        { required: true, message: 'CPF obrigatório', trigger: 'blur' },
      ],
      tel: [
        { required: true, message: 'Telefone obrigatório', trigger: 'blur' },
      ],
      projects: [
        {
          required: true,
          message: 'Projeto obrigatório',
          trigger: 'submit',
        }
      ],
      id_cargo: [
        { required: true, message: 'Cargo obrigatório',  trigger: 'submit' }
      ]
    })

    const formatCPF = (value) => {
      if (!value) return '';
      return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    };

    const formatTelefone = (value) => {
      if (!value) return '';
      return value
        .replace(/\D/g, '') // Remove non-numeric characters
        .replace(/(\d{2})(\d)/, '($1) $2') // Add parentheses around the area code
        .replace(/(\d{1})(\d{4})(\d{4})/, '$1 $2-$3'); // Adds a space after the 9 and a hyphen before the last 4 digits
    };

    const parseCPF = (value) => {
      return value ? value.replace(/\D/g, '') : '';
    };

    const parseTelefone = (value) => {
      return value ? value.replace(/\D/g, '') : '';
    };



    const arrayProjects: Ref<any[]> = ref([]);
    const usuarioLojas: Ref<any> = ref([]);

    const loadSave: Ref<boolean> = ref(false);
    const loadExcluir: Ref<boolean> = ref(false);
    const loadRemoveUsuario: Ref<boolean> = ref(false);
    const loadResetarSenha: Ref<boolean> = ref(false);
    const dialogVisible: Ref<boolean> = ref(false);

    const disableSalvar: Ref<boolean> = ref(true);
    const clikouResetarSenha: Ref<boolean> = ref(false);
    const novaSenha: Ref<string> = ref('tickets');


    const adicionarUsuarioEmNovaLoja: Ref<boolean> = ref(false)

    const arrayCargos: Ref<any[]> = ref([]);
    const idLoja: Ref<any> = ref(null);


    const submitForm = async (formEl: FormInstance | undefined) => {
      //verifica se os campos foram preenchidos ok
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          salvaUsuario()
        } else {
          console.log('error submit!', fields)
        }
      })
    }
    const resetForm = () => {
      ruleFormRef.value?.resetFields()
    };

    async function salvaUsuario() {

      loadSave.value = true 
      const { data } = await Api.post("user", { ...user});  

      loadSave.value = false

      if(data.error) return showTimeAlert('Verifique os dados e tente novamente', "error");

      enviaUsuarioAtualizado(data)

      fecharModal()

      return showTimeAlert('usuário salvo com sucesso!', "success")

    }
    // ---------------------------------

    function fecharModal(){
      // usuarioRef.value = {};
      props.fechaModal && props.fechaModal()

    }

    function abreModal(){
      resetForm()
      const { name, email, password, projects, cpf, tel, id, id_cargo } = props.usuario

      user.name = name
      user.email = email
      user.password = password
      user.projects = projects
      user.cpf = cpf
      user.tel = tel
      user.id = id
      user.id_cargo = id_cargo
    }

    function enviaUsuarioAtualizado(usuarioParam) {
      emitter.emit('enviaUsuarioAtualizado', { usuario: usuarioParam })
    }

    function removeUsuarioLista(usuarioParam) {
      emitter.emit('removeUsuarioLista', { usuario: usuarioParam })
    }

    async function resetarSenha() { 

      loadResetarSenha.value = true

      const { data } = await Api.put("changePassword", {...user, password: novaSenha.value })

      loadResetarSenha.value = false

      if(data.error) return showTimeAlert(data?.message, "error")

      novaSenha.value = ""

      return showTimeAlert('Senha resetada com sucesso!', "success")
      
    }

    async function deletarUsuario() { 

      loadExcluir.value = true

      const { data } = await Api.delete("deleteUser", { id: user.id })

      loadExcluir.value = false

      if(data.error) return showTimeAlert(data?.message, "error")

      removeUsuarioLista(data)

      dialogVisible.value = false;
      fecharModal()

      return showTimeAlert('usuário deletado com sucesso!', "success")
      
    }

    

    async function getProjects() {

      const {data} = await Api.get("projects");

      arrayProjects.value = data;

    }

    async function getCargo() {

      const {data} = await Api.get('getCargosUsuario');
      arrayCargos.value = data;
    }

    // abrindo modal com usuario
    watch(() => props.usuario, async () => { abreModal() }, { deep: true })
    

    onMounted(async()=>{
      getCargo()
      getProjects()
    })
   
    return {
      fecharModal,
      deletarUsuario,
      resetarSenha,
      formatTelefone,
      parseTelefone,
      formatCPF,
      parseCPF,
      submitForm,
      loadSave,
      loadRemoveUsuario,
      arrayCargos,
      loadExcluir,
      arrayProjects,
      adicionarUsuarioEmNovaLoja,
      clikouResetarSenha,
      novaSenha,
      loadResetarSenha,
      usuarioLojas,
      idLoja,
      dialogVisible,
      user,
      disableSalvar,
      ruleFormRef,
      rules
    };
  },
});
